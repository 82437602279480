<template>
  <div id="app">
    <div id="nav">
      <mt-tabbar v-model="selected" fixed>
        <mt-tab-item id="/">
          <router-link to="/" replace>
            <img :src="require('@/assets/page1/btn_d.png')" width="100%">
          </router-link>
        </mt-tab-item>
        <mt-tab-item id="/head">
          <router-link to="/head" replace>
            <img :src="require('@/assets/page1/btn_e.png')" width="100%">
          </router-link>
        </mt-tab-item>
      </mt-tabbar>
    </div>
    <router-view />
    <img class="hand" :src="require('@/assets/page2/heart.png')" width="10%">
  </div>
</template>
<script>
export default {
  name: "Home",
  data(){
    return {
      selected: '/'
    }
  }
};
</script>

<style>
.mint-tab-item{
  padding: 0 0 !important;
}
.mint-tab-item-label a {
  color: #2c3e50;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: fit-content;
  font-size:.8rem;
}
.mint-tab-item-label a:hover{
  text-decoration: none;
}
.hand{
  position: fixed;
  left: 45%;
  bottom: 3.5%;
  z-index: 2;
}
</style>
