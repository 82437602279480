<template>
  <div class="home">
    <img class="title" :src="require('@/assets/page1/title.png')" width="60%">
    <div class="row">
      <div style="width:40%"><img :src="require('@/assets/page1/number.png')" width="60%"></div>
      <div style="width:60%"><input class="no1" v-model="no1" maxlength="3"/></div>
    </div>
    <div class="row">
      <div style="width:40%"><img :src="require('@/assets/page1/number2.png')" width="60%"></div>
      <div style="width:60%"><input class="no1" v-model="no2" maxlength="4"/></div>
    </div>
    <div class="row">
      <img :src="require('@/assets/page1/hint_txt1.png')" width="8%">
      <img :src="resultImg" width="100%">
      <img :src="require('@/assets/page1/hint_txt2.png')" width="8%">
    </div>
    <div class="row" style="justify-content: space-between;">
      <img :src="require('@/assets/page1/btn_a.png')" @click="choice('a')" width="20%">
      <img :src="require('@/assets/page1/btn_b.png')" @click="choice('b')" width="20%">
      <img :src="require('@/assets/page1/btn_c.png')" @click="choice('c')" width="20%">
    </div>
    
    <vue-canvas-poster :widthPixels="canvasWidth" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>

  </div>
</template>

<script>
// @ is an alias to /src
import { VueCanvasPoster } from 'vue-canvas-poster'
export default {
  name: "Home",
  components: {
      VueCanvasPoster,
  },
  data(){
    return {
      selected: null,
      no1: '',
      no2: '',
      canvasWidth: document.documentElement.clientWidth * 0.6,
      resultImg: '',
      painting: {
        width: '500px',
        height: '500px',
        background: '#f4f5f7',
        views: [
          {
            type: 'image',
            url: require('@/assets/page1/blocka.png'),
            css: {
              width: '500px',
              height: 'auto',
              top: '0px'
            }
          },
          {
            type: 'text',
            text: '45#',
            css: [
                {
                    top: '50px',
                    align: 'center',
                    left:'250px',
                    fontSize: '150px',
                    fontFamily: '黑体'
                }
            ]
          },
          {
            type: 'text',
            text: '201',
            css: [
                {
                    bottom: '80px',
                    align: 'center',
                    left:'250px',
                    fontSize: '150px',
                    fontFamily: '黑体'
                }
            ]
          }
        ]
      }
    }
  },
  watch: {
    no1 (val){
      this.painting.views[1].text = val+'#'
    },
     no2 (val){
      this.painting.views[2].text = val
    },
  },
  methods: {
    success(src){
      this.resultImg = src
    },
    fail(){

    },
    choice(idx){
      this.painting.views[0].url = require('@/assets/page1/block'+idx+'.png')
    }
  }
};
</script>

<style scoped>
.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
}
.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
}
.row input{
  height: 30px;
  width: 100%;
  background-image: url('../assets/page1/input1.png');
  background-size: 100% 100%;
  border: none;
  font-size: 1.2rem;
}
</style>